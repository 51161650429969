/* --- n t r i g a . a g e n c y ---
 * web  -> ntriga.agency
 * mail -> info@ntriga.agency
 * --------------------------------- */

// Custom variables
@import 'variables';

// Bootstrap variable overrides
@import 'bootstrap-vars';

// Bootstrap
@import '../../../../vendor/twbs/bootstrap/scss/bootstrap.scss';

// Bootstrap style overrides
@import 'bootstrap';

@import 'representatives';

@import '../../plugins/select2/css/select2.min.css';
@import '../../plugins/select2/css/select2-bootstrap4.min.css';

@import 'sweetalert2/src/sweetalert2.scss';

/*
 *	DEFAULTS
 */

html {
	box-sizing: border-box;
}
*, *:before, *:after {
	box-sizing: inherit;
}
#fancybox-loading,
#fancybox-loading div,
#fancybox-overlay,
#fancybox-wrap,
#fancybox-wrap div {
	box-sizing: content-box;
}

/* hide placeholder text on focus */
input:focus::-webkit-input-placeholder { color:transparent; }
input:focus:-moz-placeholder { color:transparent; }
input:focus::-moz-placeholder { color:transparent; }
input:focus:-ms-input-placeholder { color:transparent; }

/* button outlines */
button:focus {
	outline: none;
}

.lightbox {
	display: block;
	text-decoration: none!important;
}

.ml-auto{
	margin-left: auto;
}



/*
 *	RESPONSIVE IMAGES
 */

picture {
	display: block;
	line-height: 0;
}

img[data-sizes="auto"] {
	display: block;
	width: 100%;
}

.lazy-placeholder {
	display: block;
	width: 100%;
	padding-bottom: 50%;
	background-color: #ccc;
	background-image: linear-gradient(120deg, rgba(#fff,0) 0, rgba(#fff,0) 20%, rgba(#fff,.4) 50%, rgba(#fff,0) 80%, rgba(#fff,0) 100%);
	background-repeat: repeat-x;
	background-size: 200% 100%;
	background-position: -100% 0;
	animation: lazy-loading 1s infinite linear;
	transform: translate3d(0,0,0);
}
.lazyloaded + .lazy-placeholder,
.no-js .lazy-placeholder {
	display: none!important;
}

@keyframes lazy-loading {
	0% {
		background-position: -100% 0;
	}
	100% {
		background-position: 100% 0;
	}
}



/*
 *	GENERAL
 */

body {
	overflow-x: hidden;
	background-color: #fff;
	background-image: radial-gradient(circle at 100% 0%, #fff 0%, #f1f3f5 25vw, #fff 25vw, #f7fafc 50vw, #fff 100vw);
	background-position: 0 100%;
	background-size: 100% auto;
	//background-size: cover;
	background-repeat: no-repeat;
	//background-attachment: fixed;

	min-height: 100vh;
	display: flex;
	flex-direction: column;

	/*> * {
		flex: 1 1 auto;
	}*/
}

.cover-img {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;
}

.form-group {
	margin-bottom: 15px;
}

.small-tip {
	position: relative;
	font-size: $font-size-sm;
	margin-bottom: 15px;
	padding-left: 25px;
	color: $primary;

	i {
		position: absolute;
		left: 0;
		top: 5px;
	}
}

/*
*	SELECT2 FILTERS
*/
.search-filter-wrapper{
	margin-bottom: 10px;

	.select2-prepend-icon,
	.select2-append-icon {
		background: #ffffff;
		border: 1px solid #879ab1;
		padding: 0 10px;
		line-height: 38px;

	}

	.select2-prepend-icon{
		color: #879ab1;
		border-radius: 2px 0 0 2px;
		border-right: 0;
	}

	.select2-append-icon {
		border-radius: 0 2px 2px 0;
		border-left: 0;
		color: #444444;
	}
}

.search-filter{
	display: none;

	&.select2-hidden-accessible{
		display:block;
	}
}

.select2-container--default .select2-selection--multiple{
	border: 1px solid #879ab1;
	border-radius: 0;
	border-left: 0;
	border-right: 0;
	padding: 5px 0 10px 0;
}

/*
*	SPINNER
*/
.lds-ring {
	display: inline-block;
	position: relative;
	width: 80px;
	height: 80px;

	&:after{
		content: '';
		background-image: url('/img/initials.png');
		position: absolute;
		left: 12px;
		top: 15px;
		width: 40px;
		height: 40px;
		background-position: center center;
		background-size: contain;
		background-repeat: no-repeat;
	}
}

.lds-ring div {
	box-sizing: border-box;
	display: block;
	position: absolute;
	width: 64px;
	height: 64px;
	margin: 2px;
	border: 2px solid $yellow;
	border-radius: 50%;
	animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
	border-color: $yellow transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
	animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
	animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
	animation-delay: -0.15s;
}

@keyframes lds-ring {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}


/*
 *	NAVBAR
 */

.navbar--top {
	flex: 0 1 auto;

	justify-content: flex-end;
	padding: 10px 0 0;
	background-color: $primary;
	background-image: linear-gradient(to right, $primary, #355c94);
	border-bottom: 1px solid $primary-dark;
	font-size: $font-size-sm;

	.navbar-nav {
		flex-direction: row;
		justify-content: flex-end;
		background-color: $primary-dark;
		padding: 10px 0;
		border-radius: 10px 10px 0 0;
	}

	.nav-item {
		margin-left: 0;
		border-right: 1px solid #fff;

		&:last-child { border-right: none; }
	}

	.nav-link {
		padding: 0 15px;
		color: #fff;
		margin-top: 10px;
	}
}

.navbar-brand {
	font-weight: 300;
	display: flex;
	align-items: center;

	.navbar-brand-logo {
		max-height: 60px;
		margin-right: 10px;
	}

	strong {
		font-weight: bold;
	}
}

.navbar--main {
	flex: 0 1 auto;
	margin-bottom: 0;
	padding: 5px 0;
	background-color: #e8edf2;

	.nav-item {
		position: relative;
		border-left: 1px solid #fff;

		&.active {
			position: relative;
		}
	}

	.nav-link {
		padding: 10px 20px!important;
		text-align: center;

		&:hover {
			color: $primary-bright;
		}
	}
	.active .nav-link,
	.nav-link.show {
		color: $primary-bright;

		&::before {
			content: '';
			display: block;
			position: absolute;
			width: 100%;
			left: 0;
			top: 100%;
			height: 8px;
			background-color: $yellow;
			margin-top: 10px;
		}
	}

	.dropdown-menu {
		margin-top: 18px;
	}

	.badge {
		background-color: $yellow;
		color: $body-color;
		border-radius: 2px;
		padding: 2px 2px 0;

		position: absolute;
		left: 27px;
		top: 4px;
		font-weight: $font-weight-normal;
	}
}

.btn{
	.badge {
		background-color: $yellow;
		color: $body-color;
		border-radius: 50%;
		font-weight: $font-weight-normal;
	}
}
.btn-secondary {
	color: $primary;
}



/*
 *	BODY NAV
 */

.nav--body {
	margin: 5px 0 50px;
	width: auto;
	align-items: flex-end;

	.nav-link {
		padding: 10px 25px;
		color: $primary;
		border: 1px solid $primary-light;
		background-color: #fff;

		&:hover,
		&:active {
			color: $primary-bright;
			border-color: $primary-bright;
			background-color: #fff;
		}
	}

	.nav-item + .nav-item {
		margin-left: -2px;
	}
	.nav-item:first-child .nav-link {
		border-top-left-radius: 10px;
		border-bottom-left-radius: 10px;
	}
	.nav-item:last-child .nav-link {
		border-top-right-radius: 10px;
		border-bottom-right-radius: 10px;
	}

	.active .nav-link {
		color: $primary-bright;
		font-weight: $font-weight-bold;
		border-color: $primary-bright;
	}
}

.search {
	width: 310px;
	max-width: 100%;
	margin-bottom: 50px;

	.form-control {
		border-color: $gray;

		&::placeholder {
			color: $primary;
			font-size: .7rem;
		}
	}

	.btn {
		border-radius: 3px;
		background-color: #fff;
		border-color: $gray;
		color: $primary;
	}
}



/*
 *	ASIDE
 */

.aside {
	margin-bottom: 60px;
	padding: 0;
	background-color: #fff;
	border: 1px solid $primary-light;
	border-radius: 2px;

	.title {
		margin-bottom: 10px;
		color: $primary-light;
		font-weight: $font-weight-bold;
		letter-spacing: .05rem;
	}

	.form-select {
		margin-bottom: 4px;
	}

	.sublist {
		padding-left: 25px;
	}
}
.aside__section {
	position: relative;
	padding: 20px;

	&::before {
		content: '';
		display: block;
		position: absolute;
		bottom: 0;
		left: 20px;
		right: 20px;
		border-bottom: 1px solid #cfd7e0;
	}
	&:last-child::before { content: none; }
}

.aside-title {
	opacity: .5;
}

.nav--aside {
	border-bottom: 1px solid rgba(#000, .1);

	.nav-item {
		width: 100%;
		border-top: 1px solid rgba(#000, .1);
	}

	.nav-link {
		padding: 13px 20px;
		line-height: 1.1;
		color: $body-color;

		&:hover {
			color: $primary;
		}
		&:active {
			color: darken($primary, 10%);
		}
	}
	.active > .nav-link {
		font-weight: bold;
		color: $primary;
	}

	.subnav {
		padding-left: 20px;
		border-top: 1px solid rgba(#000, .1);

		.nav-item:first-child {
			border-top: none;
		}
	}
}

.nav--side {
	flex-direction: column;

	.nav-item {
		margin: 5px 0;
	}
	.nav-link {
		padding: 0;
		font-size: $font-size-sm;

		&:hover {
			color: $primary-bright;
		}
	}
	.active .nav-link {
		color: $primary-bright;
	}
}



/*
 *	MAIN
 */

.main {
	flex: 1 1 auto;
	margin-bottom: 60px;
}


/*
 *	DASHBOARD
 */

.dashboard-intro {
	margin-bottom: 60px;

	.title-fade {
		margin-bottom: 5px;
		font-size: 3rem;
		color: #d7dfe7;
		line-height: 1.2;
		letter-spacing: .2rem;
	}
}

.dash-cta {
	flex: 1 1 auto;

	display: block;
	margin-bottom: 50px;
	text-decoration: none;
	background-color: #e8edf2;

	&:hover,
	&:active {
		background-color: #a7d7f5;

		.dash-cta__overlay {
			background-color: rgba(#a1d7f8, .7)!important;
			border-bottom-color: transparent;

			.title {
				color: #fff!important;
			}
			.icon {
				filter: grayscale(100%) brightness(10);
			}
		}
	}
}
.dash-cta__overlay {
	min-height: 100%;
	padding: 30px;
	border-bottom: 4px solid #b2c4d7;
	transition: background-color .2s ease-in-out, border-bottom .2s ease-in-out;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;


	.icon {
		flex: 0 0 auto;
		width: 60px;
		max-width: 100%;
		margin-bottom: 10px;
		transition: filter .2s ease-in-out;
	}
	.title {
		font-size: 2rem;
		text-transform: uppercase;
		text-align: center;
		line-height: 1.2;
		color: $primary;
		transition: color .15s ease-in-out;
	}
}

.dash-cta--big {
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;

	.dash-cta__overlay {
		font-weight: $font-weight-bold;
		border-bottom: 10px solid $primary;
		background-color: rgba(#fff, .7);

		.icon {
			width: 100px;
			margin-bottom: 15px;
		}
	}
}

.btn-eye{
	background-color: #FFFFFF;
	border-radius: 0;
	border: 1px solid #ced4da;

	&:focus, &:active{
		outline: none;
	}

	&:hover, &.active{
		background-color: #ced4da;
	}
}


/*
 *	DASHBOARD: REPRESENTATIVE
 */

.form-group--clientsearch {
	margin-top: 45px;
	margin-bottom: 15px;

	.btn {
		width: 50px;
		padding-right: 5px;
		padding-left: 0;
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
	}
}

.title-date {
	margin-top: 60px;
	margin-bottom: 15px;
	color: $primary-light;
	font-size: 1.8rem;
	font-weight: $font-weight-light;
	line-height: 1.2;

	i {
		display: inline-block;
		margin-right: 8px;
		opacity: .8;
	}
}

.alphabet {
	display: flex;
	width: 100%;
}
.alphabet__letter {
	order: 1;
	flex: 0 0 50px;
	width: 50px;

	font-size: 1.8rem;
	font-weight: $font-weight-light;
	line-height: 1.2;
	color: $primary-light;
	position: relative;
	text-align: center;

	.letter {
		position: sticky;
		top: 0;
	}
}
.alphabet__content {
	order: 0;
	flex: 1 1 auto;
}



/*
 *	CATEGORY
 */

.category {
	margin-bottom: 30px;

	display: flex;
	flex-direction: column;

	a {
		flex: 1 0 auto;

		display: flex;
		flex-direction: column;
	}
}
.category__img {
	position: relative;
	border-radius: 3px;
	overflow: hidden;

	img {
		max-width: 100%;
	}

	&::before {
		content: '';
		display: block;
		z-index: 1;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background-color: rgba(#fff, .5);

		opacity: 0;
		transition: opacity .2s ease-out, background .2s ease-out;
	}
}
.category__title {
	text-align: center;
	position: relative;
	z-index: 1;
	padding: 0 15px;

	.btn {
		padding: 15px 25px;
		border-radius: 30px;
		background-color: #fff;
		border: 1px solid $gray;
		margin-top: -25px;

		&:hover {
			border-color: $yellow;
			color: $yellow;
		}
	}
}

.category {
	a {
		text-decoration: none;

		&:hover,
		&:focus,
		&:active {
			.category__img {
				img {
					filter: grayscale(1);
				}

				&::before {
					opacity: 1;
				}
			}
			.category__title .btn {
				border-color: $yellow;
				box-shadow: 0 0 0 2px $yellow;
				color: $yellow;
			}
		}
		&:active {
			.category__img {
				&::before {
					background-color: rgba(darken($primary, 10%), .5);
				}
			}
			.category__title .btn {
				border-color: $primary-bright;
				color: $primary-bright;
				box-shadow: 0 0 0 2px $primary-bright;
			}
		}
	}
}



/*
 *	PRODUCT LABELS
 */

.label {
	margin-bottom: 6px;
	padding: 3px 15px;
	border-radius: 3px;
	background-color: $orange;
	color: #fff;
	font-weight: $font-weight-bold;
	font-size: 1.1rem;
	line-height: 1.2;
	letter-spacing: .05rem;
	box-shadow: 0 0 6px rgba(#000, .2);
}
.label--warning {
	padding: 5px 10px;
	font-size: 1rem;
	color: $red;
	border: 1px solid $red;
	background-color: #fff;
	font-size: .8rem;
	font-weight: $font-weight-normal;
	box-shadow: none;
}



/*
 *	PRODUCT
 */

.btn--heart {
	z-index: 2;
	position: absolute;
	top: 0;
	right: 0;
	text-shadow: 0 0 15px rgba(#000, .4);
	font-size: 1.5rem;
	color: #fff;
	padding: 10px;

	&:hover {
		color: $yellow;
	}
}


.product {
	display: flex;
	flex-direction: column;

	margin-bottom: 30px;
	background-color: #fff;
	border-radius: 3px;
	border: 1px solid #e7ecf1;

	position: relative;
	transition: border .15s ease-in-out, box-shadow .25s ease-in-out;

	&:hover {
		border-color: $yellow;
		box-shadow: 0 0 30px rgba(#000, .2);
	}
}
.product__img {
	order: 0;
	flex: 0 0 auto;
	max-width: 100%;
	min-height: 1px;


	a:not(.btn--heart) {
		display: block;
		position: relative;

		&::before {
			content: '';
			display: block;
			z-index: 1;
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			background-color: rgba(#fff, .5);

			opacity: 0;
			transition: opacity .2s ease-out, background .2s ease-out;
		}

		&:hover,
		&:focus,
		&:active {
			&::before {
				opacity: 1;
			}
		}
		&:active {
			&::before {
				background-color: rgba(#fff, .5);
			}
		}
	}

	img {
		border-radius: 3px 3px 0 0;
	}

	.labels {
		z-index: 2;
		position: absolute;
		top: 20px;
		left: -5px;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
	}
}
.product__desc {
	order: 2;
	flex: 1 0 auto;
	max-width: 100%;
	min-height: 1px;

	padding: 15px;

	.labels {
		display: flex;
		margin-top: -20px;
		margin-bottom: 10px;
		min-height: 27px;
	}
	.label {
		margin-right: 10px;
	}

	.title {
		margin-bottom: 15px;
		font-size: $font-size-base;
		font-weight: $font-weight-bold;
		letter-spacing: .05rem;

		a {
			color: $primary;
			text-decoration: none;

			&:hover,
			&:focus {
				color: $yellow;
			}
			&:active {
				color: $primary-bright;
			}
		}
	}

	.previous {
		margin: 3px 0;
		font-size: $font-size-sm;
		line-height: 1.2;
		color: $primary-light;

		display: flex;
		flex-wrap: wrap;
	}
	.previous__date {
		flex: 0 0 auto;
		margin-bottom: 5px;

		font-size: $font-size-sm;
		padding-top: 3px;
	}
	.previous__amount {
		flex: 0 0 auto;

		margin-bottom: 5px;
		margin-left: 5px;
		padding-left: 5px;
		padding-top: 3px;
		border-left: 1px solid rgba($primary-light, .5);
	}

	.price {
		margin-bottom: 0;
		font-size: 1.1rem;
		font-weight: $font-weight-bold;
		color: $primary-bright;
		letter-spacing: .05rem;
	}
	.price__small {
		font-size: .85rem;
		margin-right: 8px;
	}
	.price__old {
		color: $gray;
		text-decoration: line-through;
		font-size: .85rem;
		font-weight: $font-weight-normal;
	}
	.price--promo {
		color: $orange;
	}
}
.product__buttons {
	order: 1;
	flex: 0 0 auto;
	max-width: 100%;
	position: relative;
	z-index: 2;
	padding: 0 15px;

	.inner {
		margin-top: -22px;

		display: flex;
		justify-content: space-between;
	}

	.input-group--amount {
		margin-top: 5px;
		width: 300px;
		max-width: 100%;
	}

	.form-control,
	.form-select {
		height: 45px;
	}

	.btn {
		padding: 15px 20px;
		background-color: #fff;
		border-radius: 30px;
		border-color: #a9a9a9;
		white-space: nowrap ;

		&.btn-outline-danger{
			border-color: #ff3600;

			&:hover {
				color: #ffffff;
				border-color: transparent;
				background-color: #ff3600;
				box-shadow: 0 0 0 2px #ff3600;

				i {
					transform: scale(1.2);
				}
			}
		}

		&:hover {
			color: $yellow;
			border-color: $yellow;
			box-shadow: 0 0 0 2px $yellow;

			i {
				transform: scale(1.2);
			}
		}
	}
	.btn--add {
		margin-top: 10px;
		width: 100%;
		&.btn--add__list{
			width: 100px;
			height: 45px;
			margin-top: 0;
		}

		i {
			transition: transform .2s ease-in-out;
		}
	}
}

.product__badge {
	z-index: 3;
	position: absolute;
	top: 0;
	right: 0;
	width: 70px;
	height: 70px;
	border-radius: 50%;
	background-color: $red;
	color: #fff;
	transform: translate(30%, -30%);
	text-align: center;
	line-height: 1;
	padding: 10px 5px;
	font-weight: bold;
}

.product--listed {
	flex-direction: row;
	align-items: center;

	.product__img {
		position: relative;
		order: 0;
		flex: 0 0 120px;
		max-width: 120px;
	}
	.product__desc {
		order: 1;
		flex: 1 1 auto;

		.title {
			margin-bottom: 5px;
		}
	}
	.product__buttons {
		order: 2;
		flex: 1 0 auto;
		padding: 15px 15px 15px 0;
		margin-bottom: 0;
		height: auto;

		.inner {
			position: static;
			transform: none;
			justify-content: flex-end;
		}

		.form-control+.btn, .btn+.btn {
			margin-left: 10px;
		}
	}

	.label {
		box-shadow: 0 3px 3px rgba(#000, .15);
	}
	.label--small { box-shadow: none; }
}


/*
 *	PRODUCT DETAIL
 */

.btn-back {
	min-width: 100%;
	color: #869ab2;
	letter-spacing: .05rem;
	font-weight: $font-weight-bold;
	border: 1px solid #869ab2;
	border-radius: 3px;
	padding: 20px;
	background-color: #fff;

	&:hover,
	&:focus {
		background-color: #fff;
		border-color: $primary;
		color: $primary;
	}
	&:active {

	}
}

.detail-bg {
	margin-bottom: 60px;
	background-color: #fff;
	border-radius: 0 5px 5px 0;
}
.detail-images {
	position: relative;

	.slider {
		margin-bottom: 15px;
	}

	.slider-nav {
		margin-bottom: 0;
		overflow: hidden;

		.slick-list {
			margin: 0 -8px;
		}
		.slide {
			padding: 0 8px;
		}
		.slick-current {
			img { border: 1px solid $primary; }
		}
	}

	.labels {
		position: absolute;
		top: 20px;
		left: -5px;
		z-index: 3;
	}
}

.detail-info {
	padding: 30px 15px;

	display: flex;
	flex-direction: column;

	/*
	> * {
		flex: 0 0 auto;
	}
	*/
}
.detail-title {
	margin-bottom: 5px;
	font-weight: bold;
	font-size: 1.4rem;
	color: $primary-dark;
}
.detail-reference {
	margin-bottom: 0;
	color: $primary-light;
	font-size: 1.4rem;
	font-weight: $font-weight-light;
}
.detail-price {
	margin-bottom: 10px;
	font-size: 1.6rem;
	font-weight: $font-weight-bold;
	line-height: 1;
	color: $orange;

	.smaller {
		font-size: $font-size-sm;
		font-style: italic;
		font-weight: $font-weight-normal;
		color: $primary-light;
	}
	.old {
		padding-left: 10px;
		display: inline-block;
		color: $primary-light;
		text-decoration: line-through;
		font-size: 1rem;
		font-weight: $font-weight-normal;
	}
}
.detail-labels {
	margin-bottom: 30px;
	display: flex;
	flex-wrap: wrap;

	.label {
		margin: 0 5px 5px 0;
	}
}
.detail-buttons {
	margin-top: 30px;

	.btn--cart {
		border: 2px solid $yellow;
		color: $primary-dark;
		letter-spacing: .05rem;
		background-color: #fff;

		&:hover,
		&:focus,
		&:active {
			color: $primary;
			box-shadow: 0 0 0 2px $yellow;
		}
		&:active {
			color: $primary-bright;
			box-shadow: 0 0 0 2px $primary-bright;
		}
	}
}

.detail-text {
	font-size: .85rem;
	line-height: 2;
}


/*
 *	AMOUNT PICKER
 */

.input-group--amount {
	margin-bottom: 5px;
	flex-wrap: nowrap;

	.form-control,
	.btn {
		background-color: transparent;
		border: 1px solid #b4bbc5;
	}
	.form-control {
		flex: 0 1 auto;

		font-weight: $font-weight-bold;
		font-size: 1.2rem;
		color: $primary;
		width: 60px;
		text-align: center;
		padding-left: 8px;
		padding-right: 8px;
	}

	.btn {
		flex: 0 1 auto;

		color: $primary-light;
		border-radius: 3px;
		padding-left: 15px;
		padding-right: 15px;

		&:hover,
		&:active {
			color: $primary;
			border-color: $primary;
			background-color: #fff;
		}
	}

	.form-select {
		flex: 1 1 auto;

		background-color: transparent;
		border: 1px solid #b4bbc5;
		color: $primary;

		&:hover,
		&:active {
			border-color: $primary;
			background-color: #fff;
		}
	}
}




/*
 *	LOGIN
 */

.login-title {
	color: #d8dfe7;
	font-size: 3.5rem;
	letter-spacing: .1rem;
	line-height: 1.2;
	margin-bottom: 15px;
	padding-left: 15px;
}

.title-faded {
	color: #d8dfe7;
}



/*
 *	BROCHURES
 */

.brochure {
	margin-bottom: 30px;

	background-color: #fff;
	border: 1px solid #ebeef3;
	border-radius: 3px;
}
.brochure__img {
	text-align: center;

	a {
		display: block;
	}
	img {
		width: 100%;
	}
}
.brochure__desc {
	padding: 10px 15px 0;
	text-align: center;

	.title {
		margin-bottom: 5px;
		font-size: 1rem;
		letter-spacing: .05rem;

		a {
			color: $primary-bright;
			text-decoration: none;

			&:hover {
				color: $yellow;
			}
		}
	}

	.btn {
		transform: translateY(50%);
	}
}

.brochure-small {
	margin: 0 auto 30px;
	width: 215px;
	max-width: 100%;
	background-color: #fff;
	border: 1px solid #ebeef3;
	border-radius: 3px;
}
.brochure-small__img {
	text-align: center;

	img {
		width: auto;
		max-width: 100%;
	}
}
.brochure-small__desc {
	padding: 15px;
	text-align: center;

	a {
		text-decoration: none;
	}

	.title {
		margin-bottom: 5px;
		font-size: 1rem;
		letter-spacing: .05rem;
	}
}



/*
 *	PROFILE
 */

.title-overview {
	margin-bottom: 10px;
	font-weight: $font-weight-bold;
	color: $primary-dark;
	font-size: 1.4rem;
	letter-spacing: .05rem;
}

.profile {
	font-size: $font-size-sm;
	color: $primary-dark;

	.col--border {
		border-left: 1px dashed $primary-light;
	}

	.table {
		margin-bottom: 30px;

		th {
			padding-left: 0;
		}
	}

	.form-group {
		margin-bottom: 60px;
	}
}


/*
 *	RECENT ORDERS
 */

.table--recent-orders {
	td, th {
		padding: 30px 15px!important;
	}
}





/*
 *	CART
 */

.cart-items {
	margin-bottom: 30px;
}
.cart-item {
	padding: 20px 0;
	border-bottom: 1px solid $gray;
	border-top: 1px solid $gray;

	display: flex;

	&:first-child {
		border-top: none;
	}
	&:last-child {
		border-bottom: none;
	}
}
.cart-item__img {
	flex: 0 0 15%;
	width: 15%;

	padding-right: 15px;

	img {
		max-width: 100%;
	}
}
.cart-item__details {
	flex: 1 1 35%;
	width: 35%;

	padding-right: 15px;

	a {
		text-decoration: none;
	}

	.title {
		margin-bottom: 5px;
		font-weight: $font-weight-bold;
		color: $primary;
		line-height: 1.3;
	}
	.desc {
		margin-bottom: 15px;
		font-size: $font-size-sm;
	}
}
.cart-item__price {
	width: 25%;

	padding-left: 15px;
	color: $primary-bright;
	white-space: nowrap;
	border-left: 1px solid $gray;

	.old {
		color: $primary-light;
		text-decoration: line-through;
		display: inline-block;
		white-space: nowrap;
		margin-left: 5px;
	}

	.input-group-text {
		height: 45px;
		padding: 5px;
		font-size: $font-size-sm;
		background-color: transparent;
	}
	.form-control {
		padding-left: 5px;
		padding-right: 5px;
		height: 45px;
	}
}
.cart-item__actions {
	flex: 1 0 auto;
}
.cart-item__buttons {
	flex: 0 1 auto;

	text-align: right;
	padding-left: 10px;

	.btn {
		color: $primary;

		&:hover,
		&:active {
			color: $danger;
		}
	}
}


.cart-item + .cart-item {
	border-top: none;
}

.cart-actions__amount {
	flex: 1 1 auto;

	display: flex;
	align-items: center;
	justify-content: stretch;

	.input-group--amount {
		width: 390px;
		max-width: 100%;
		min-width: 100%;
	}

	.form-select {
		flex-grow: 1;
	}

	.price {
		margin: 0;
		padding-left: 30px;
		font-size: 1.1rem;
		font-weight: $font-weight-bold;
		color: $primary-bright;
		letter-spacing: .05rem;
		text-align: right;
	}
}
.cart-actions__discount {
	flex: 1 1 auto;

	.input-group--discount {
		margin-top: 10px;
		flex-wrap: nowrap;
	}
}


.cart-total {
	margin-bottom: 30px;
	padding: 20px;
	background-color: #fff;
	border: 1px solid $primary-light;
	color: $primary-dark;
	border-radius: 3px;

	.title {
		margin-bottom: 15px;
		font-size: 1.2rem;
		font-weight: bold;
		color: $primary-light;
	}

	table {
		width: 320px;
		max-width: 100%;
		margin-bottom: 15px;
	}

	td {
		padding: 8px 0;
	}
	td:last-child {
		padding-left: 20px;
		text-align: right;
		font-weight: $font-weight-bold;
	}

	tfoot {
		font-weight: bold;
		border-top: 1px solid $primary;
		color: $primary-bright;

		td { padding-top: 10px; }
	}

	.line-top {
		border-top: 1px solid $primary;
	}



	.btn {
		clear: both;
	}
}


.cart-info {
	margin-bottom: 30px;
	font-size: $font-size-sm;

	.title {
		margin-bottom: 10px;
		font-weight: bold;
		font-style: normal;
	}

	p {
		margin-bottom: 15px;
		font-style: italic;
	}
}

.success-message {
	color: $primary-bright;
	letter-spacing: .05rem;

	.title {
		margin-bottom: 0;
		font-size: 1.4rem;
		font-weight: $font-weight-bold;
	}
	.intro {
		margin-bottom: 30px;
		font-size: 1.1rem;
		font-weight: $font-weight-bold;
	}
}



/*
 *	ORDERS / INVOICES
 */

.table--fancy {
	font-size: $font-size-sm;

	tr {
		position: relative;
	}

	td, th {
		padding: 25px 20px!important;
		vertical-align: middle;
	}

	td {
		position: relative;
	}
	td::before {
		content: '';
		position: absolute;
		top: 25px;
		bottom: 25px;
		left: 0;
		width: 1px;
		background-color: $primary-light;
	}

	a {
		text-decoration: none!important;
	}

	.price {
		font-weight: $font-weight-bold;
		color: $primary-bright;
	}

	.actions {

		&::before {
			content: none;
		}
	}

	.btn-view {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		text-align: right;
		font-size: 1.5rem;
		padding: 0;
		color: $primary-dark;
		box-shadow: none !important;
		cursor: pointer;

		display: flex;
		flex-direction: column;
		align-items: flex-end;
		justify-content: center;

		&::before {
			z-index: -1;
			content: '';
			display: block;
			position: absolute;
			top: 15px;
			bottom: 15px;
			right: 0;
			left: 0;
			background-image: linear-gradient(to right, #ecf1f5, transparent);
			opacity: 0;
			transition: opacity .2s ease-in-out;
		}

		&:hover {
			color: $yellow;

			&::before { opacity: 1; }
		}
		&:active {
			color: $primary-bright;

			&::before { opacity: 1; }
		}
	}
}


/*
 *	CHECKOUT PROGRESS
 */

.checkout-steps {
	display: flex;
	margin-bottom: 60px;
}
.checkout-step {
	flex: 1 1 25%;

	margin: 0 1px;
	padding: 15px 7px;
	background-color: $gray-200;
	color: $secondary;
	text-align: center;
	opacity: .5;
}
.checkout-step__number {
	margin-bottom: 5px;
	font-size: 1.2rem;
	font-weight: bold;
	line-height: 1;
}
.checkout-step__title {
	font-size: $font-size-sm;
	font-weight: bold;
}

.checkout-step--active {
	opacity: 1;
	color: $primary;
}
.checkout-step--done {
	opacity: 1;
}



/*
 *	CHECKOUT 1
 */

.fieldset {
	margin-bottom: 30px;
}

.cart-total--sticky {
	width: 100%;
	position: sticky;
	top: 30px;
}



/*
 *	CHECKOUT 2
 */

.table--summary {
	margin-bottom: 60px;
}



/*
 *	CHECKOUT 3
 */

.payment-radio {
	margin-bottom: 30px;
	padding: 0;
	border-radius: 5px;

	img {
		width: 75px;
		margin-right: 10px;
		vertical-align: middle;
	}

	.custom-control-label {
		display: block;
		padding: 10px 15px 10px 40px;
		background-color: $gray-200;
		border-radius: 5px;
		cursor: pointer;
		transition: background .15s ease-out;

		&::before,
		&::after {
			left: 10px;
			top: 50%;
			transform: translateY(-50%);
		}
	}

	.custom-control-input:checked ~ .custom-control-label {
		background-color: $primary;
		color: #fff;
		font-weight: bold;

		&::before {
			border-color: #fff;
		}
	}
}



/*
 *	TABLE
 */

.table {
	margin-bottom: 30px;
}

.dataTable {
	margin-bottom: 30px!important;
}



/*
 *	PAGINATION
 */

.pagination {
	margin-top: 30px;
	margin-bottom: 30px;
}



/*
 *	BREADCRUMB
 */

.breadcrumb-wrapper {
	margin-bottom: 30px;
	padding: 10px 0;
	background-color: #f7fafc;
}
.breadcrumb {
	margin-bottom: 0;
	padding: 0;
	background-color: transparent;
	font-size: $font-size-sm;

	a:hover {
		color: $primary-bright;
	}
}
.breadcrumb-item + .breadcrumb-item::before {
	content: '|';
	color: $primary-light;
}
.breadcrumb-item.active {
	color: $primary-light;
}



/*
 *	FOOTER
 */

.footer {
	flex: 0 1 auto;

	padding: 10px 0;
	color: $primary;
	background-color: #ccd6e2;
	border-top: 2px solid $primary;
}

.nav--footer {
	align-items: center;

	.nav-link {
		padding: 5px 15px;
		font-weight: $font-weight-light;
		line-height: 1.2;

		&:hover {
			color: $primary-bright;
		}
	}
	.nav-title {
		display: inline-block;
		padding: 0 15px;
		font-weight: $font-weight-bold;
		border-right: 1px solid $primary;
		line-height: 1.2;
	}
}



/*
 *	GO UP
 */

.go-up {
	z-index: 100;
	position: fixed;
	right: 0;
	bottom: 70px;
	background-color: rgba($primary, .8);
	width: 45px;
	height: 45px;
	color: #fff;
	border: none;
	border-radius: 5px 0 0 5px;
	transition: background-color .15s ease-in-out;

	&:hover {
		background-color: lighten($primary, 5%);
	}
	&:active {
		background-color: darken($primary, 5%);
	}
}

.go-down {
	z-index: 100;
	position: fixed;
	right: 0;
	bottom: 15px;
	background-color: rgba($primary, .8);
	width: 45px;
	height: 45px;
	color: #fff;
	border: none;
	border-radius: 5px 0 0 5px;
	transition: background-color .15s ease-in-out;

	&:hover {
		background-color: lighten($primary, 5%);
	}
	&:active {
		background-color: darken($primary, 5%);
	}
}



/*
 *	SLICK SLIDER
 */
/*
.slick-slider {
	position: relative;
	margin-bottom: 30px;
	line-height: 1;

	.slick-arrow {
		z-index: 2;
		position: absolute;
		top: 0;
		bottom: 0;
		width: 40px;
		padding: 0;
		background-color: transparent;
		border: none;
		text-align: center;
		cursor: pointer;
	}
	.slick-prev {
		left: 0;
	}
	.slick-next {
		right: 0;
	}
}
*/


/*
 *	RECAPTCHA
 */

.g-recaptcha {
	margin-top: 30px;
}
.grecaptcha-badge {
	max-width: 100%;
	overflow: hidden;
}


/*
 *	COOKIES
 */

.ntr-cookies {
	position: fixed;
	right: 0;
	bottom: 0;
	left: 0;
	max-height: 100vh;
	overflow-y: auto;
	padding: 30px 0;
	background-color: #fff;
	box-shadow: 0 0 40px rgba(#000, .1);
}



/*
 *	IMPORTS
 */

@import 'fontello';
//@import 'webshop';
@import 'responsive';
