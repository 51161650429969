.representatives-view {

	input[type="search"]::-webkit-search-cancel-button {

	  /* Remove default */
	  -webkit-appearance: none;

	  /* Now your own custom styles */
	   height: 14px;
	   width: 14px;
	   display: block;
	   background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAAn0lEQVR42u3UMQrDMBBEUZ9WfQqDmm22EaTyjRMHAlM5K+Y7lb0wnUZPIKHlnutOa+25Z4D++MRBX98MD1V/trSppLKHqj9TTBWKcoUqffbUcbBBEhTjBOV4ja4l4OIAZThEOV6jHO8ARXD+gPPvKMABinGOrnu6gTNUawrcQKNCAQ7QeTxORzle3+sDfjJpPCqhJh7GixZq4rHcc9l5A9qZ+WeBhgEuAAAAAElFTkSuQmCC);
	  /* setup all the background tweaks for our custom icon */
	  background-repeat: no-repeat;

	  /* icon size */
	  background-size: 14px;

	}

	.product-combo {
		display: flex;
		width: 100%;
	}

	.product-img {
		flex: 0 1 60px;
		max-width: 60px;
		padding-right: 10px;

		img {
			flex: 0 0 auto;

			width: auto!important;
			max-width: 50px;
			height: auto!important;
			max-height: 50px;
			align-self: center;
			margin-right: 10px;
		}
	}

	.product__desc {
		align-items: center;

		order: 2;
		flex: 1 1 auto;

		max-width: 100%;
		min-height: 1px;

		padding: 15px;


		.labels {
			display: flex;
			margin-top: -20px;
			margin-bottom: 10px;
			min-height: 27px;
		}
		.label {
			margin-right: 10px;
		}

		.title {
			margin-bottom: 5px!important;
			font-size: $font-size-base;
			font-weight: $font-weight-bold;
			letter-spacing: .05rem;

			a {
				color: $primary;
				text-decoration: none;

				&:hover,
				&:focus {
					color: $yellow;
				}
				&:active {
					color: $primary-bright;
				}
			}
		}

		.previous {
			margin: 3px 0;
			font-size: $font-size-sm;
			line-height: 1.2;
			color: $primary-light;

			display: flex;
			flex-wrap: wrap;
		}
		.previous__date {
			flex: 0 0 auto;
			margin-bottom: 5px;

			font-size: $font-size-sm;
			padding-top: 3px;
		}
		.previous__amount {
			flex: 0 0 auto;

			margin-bottom: 5px;
			margin-left: 5px;
			padding-left: 5px;
			padding-top: 3px;
			border-left: 1px solid rgba($primary-light, .5);
		}

		.price {
			margin-bottom: 0;
			font-size: 1.1rem;
			font-weight: $font-weight-bold;
			color: $primary-bright;
			letter-spacing: .05rem;
		}
		.price__small {
			font-size: .85rem;
			margin-right: 8px;
		}
		.price__old {
			color: $gray;
			text-decoration: line-through;
			font-size: .85rem;
			font-weight: $font-weight-normal;
		}
		.price--promo {
			color: $orange;
		}

		.small {
			margin-bottom: 5px;
		}
	}

	.search {
		width: 310px;
		max-width: 100%;
		margin-bottom: 10px;

		.form-control {
			border-color: $gray;

			&::placeholder {
				color: $primary;
				font-size: .7rem;
			}
		}

		.btn {
			border-radius: 3px;
			background-color: #fff;
			border-color: $gray;
			color: $primary;
		}
	}


	/*
     *	AMOUNT PICKER
     */

	.input-group--amount {
		margin-bottom: 5px;
		margin-bottom: 0;
		flex-wrap: nowrap;

		.form-control,
		.btn {
			background-color: transparent;
			border: 1px solid #b4bbc5;
		}
		.form-control {
			flex: 0 1 auto;

			font-weight: $font-weight-bold;
			font-size: 1.2rem;
			color: $primary;
			width: 60px;
			width: 60px !important;
			text-align: center;
			padding-left: 8px;
			padding-right: 8px;
			padding-left: 5px;
			padding-right: 5px;
			background-color: #fff;
		}

		.btn {
			flex: 0 1 auto;

			color: $primary-light;
			border-radius: 3px;
			padding-left: 15px;
			padding-right: 15px;

			&:hover,
			&:active {
				color: $primary;
				border-color: $primary;
				background-color: #fff;
			}
		}

		.form-select {
			flex: 1 1 auto;

			background-color: transparent;
			width: 120px;

			padding: 5px 8px;
			border: 1px solid #b4bbc5;
			color: $primary;
			white-space: nowrap;
			padding-right: 30px;
			text-overflow: ellipsis;

			&:hover,
			&:active {
				border-color: $primary;
				background-color: #fff;
			}
		}
	}



	/*
     *	DISCOUNT PICKER
     */

	.input-group--discount {
		flex-wrap: nowrap;

		.input-group-text {
			padding: 6px 8px;
			background-color: #f7fafc;
			border-color: #b4bbc5;
			color: rgba($body-color, .5);
		}
		.form-control {
			padding: 5px;
			border-color: #b4bbc5;
		}
	}

	.master-input-group {
		display: flex;
		align-items: flex-start;

		.input-group + .input-group {
			margin-left: 10px;
		}
	}



	/*
     *	START POWER USER TABLE
     */

	.table--power-user {
		td, th {
			padding: 8px 10px!important;
		}

		.td--first {
			width: 45%;
		}

		tr {
			transition: background-color .2s ease-out;
		}
		.tr--success {
			background-color: rgba(167, 255, 214, .8);
		}


		// Overrides
		.form-control,
		.form-select {
			height: 35px;
			background-color: #fff;
		}

		.product__desc {
			display: flex;
			flex-wrap: wrap;
		}
		.labels {
			flex: 1 0 100%;
			// override
			margin-top: 0;
			margin-bottom: 0;
			min-height: 0;
		}
		.label {
			margin-right: 8px;
			margin-bottom: 8px;
		}
		.title {
			margin-bottom: 10px;
			margin-right: 10px;
			letter-spacing: 0;
		}
		.previous {
			margin: 0 10px 3px 0;
		}



		.input-group--amount {
			margin-bottom: 0;

			.form-control,
			.btn {
				background-color: transparent;
				border: 1px solid #b4bbc5;
			}
			.form-control {
				flex: 0 1 auto;

				color: $primary;
				width: 60px !important;
				text-align: center;
				padding-left: 5px;
				padding-right: 5px;
				background-color: #fff;
			}

			.btn {
				flex: 0 1 auto;

				color: $primary-light;
				border-radius: 3px;
				padding-left: 15px;
				padding-right: 15px;

				&:hover,
				&:active {
					color: $primary;
					border-color: $primary;
					background-color: #fff;
				}
			}

			.form-select {
				flex: 1 1 auto;
				width: 120px;

				padding: 5px 8px;
				border: 1px solid #b4bbc5;
				color: $primary;
				white-space: nowrap;
				padding-right: 30px;
				text-overflow: ellipsis;

				&:hover,
				&:active {
					border-color: $primary;
					background-color: #fff;
				}
			}
		}

		.input-group--discount {
			flex-wrap: nowrap;

			.input-group-text {
				padding: 4px 8px;
				background-color: #f7fafc;
				border-color: #b4bbc5;
				color: rgba($body-color, .5);
			}
			.form-control {
				padding: 5px;
				border-color: #b4bbc5;
			}
		}
	}

	.master-input-group {
		display: flex;
		align-items: flex-start;

		.input-group + .input-group {
			margin-left: 10px;
		}
	}

	@media (max-width: 1150px) {
		.table--power-user {
			width: auto;
			margin-left: -10px;
			margin-right: -10px;

			td, th {
				padding: 15px 10px!important;
			}

			th {
				flex: 1 1 0;
				border-bottom: none;
			}

			tr {
				display: flex;
				flex-wrap: wrap;
			}
			td {
				flex: 1 1 50%;
				max-width: 50%;
				display: block;
			}
			td:first-child {
				display: block;
				flex: 1 0 100%;
				max-width: 100%;
				border-bottom-width: 0;
				padding-bottom: 0!important;
			}
		}
	}

	@media (max-width: 820px) {
		.master-input-group {
			display: block;

			.input-group + .input-group {
				margin-left: 0;
				margin-top: 10px;
			}
		}
	}

	@media (max-width: 430px) {
		.table--power-user {
			td {
				flex: 1 1 100%;
				max-width: 100%;
				width: 100%;
				display: block;
			}
			td:nth-child(2) {
				border-bottom-width: 0;
				padding-bottom: 0!important;
			}
		}
	}

	/*
     *	END POWER USER TABLE
     */


	@media (max-width: 1400px) {
		.navbar-brand {
			font-size: 0;
		}
	}
}
