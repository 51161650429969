/*
 *	CUSTOM RESPONSIVE CODE
 */
@include media-breakpoint-down(lg) {
    .navbar--main .active .nav-link::before{
        display: none !important;
    }

    .navbar--main .badge{
        position: static;
    }
}

@include media-breakpoint-down(md) {
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid {
        padding-left: 15px;
        padding-right: 15px;
    }

    /*
     *  DASHBOARD
     */

    .dashboard-intro {
        margin-bottom: 60px;

        .title-fade {
            font-size: 2rem;
            letter-spacing: .05rem;
        }
    }

    .dash-cta {
        margin-bottom: 15px;
    }
    .dash-cta__overlay,
    .dash-cta--big .dash-cta__overlay {
        .icon {
            width: 50px;
        }

        .title {
            font-size: 1.2rem;
        }
    }
    .dash-cta--big .dash-cta__overlay {

    }



    /*
     *  PRODUCT LIST
     */

    .product--listed {
        flex-wrap: wrap;

        .product__buttons {
            flex: 1 0 auto;
        }
    }


    /*
     *	CART
     */

    .cart-item {
        flex-wrap: wrap;
    }
    .cart-item__img {
    }
    .cart-item__details {
        flex: 1 1 auto;
        width: 85%;
        padding-right: 0;

        .title {
            margin-bottom: 10px;
        }
    }
    .cart-item__actions {
        flex: 1 0 auto;
        padding-top: 10px;
    }

    .cart-actions {
        align-items: flex-start;
    }
    .cart-actions__amount {
        width: 100%;

        .input-group--amount { margin-bottom: 0; }
    }
    .cart-item__buttons {
        padding-top: 15px;

        .btn {
            border-radius: 5px;
            border: 1px solid $gray;
        }
    }
}


@include media-breakpoint-down(sm) {

    /*
     *	FAVOURITES
     */

    .table--favourites {
        display: block;

        thead {
            display: block;
            width: 100%;

            tr {
                width: 100%;
                display: flex;
            }
            th {
                flex: 1 1 auto;
            }
        }

        tbody {
            display: block;

            tr {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                border-bottom: 2px solid $gray-200;
                padding-bottom: 10px;
                margin-bottom: 10px;
            }

            td {
                flex: 1 1 auto;
                align-self: center;

                display: block;
                box-sizing: border-box!important;
                border-top: none;

                &:first-child {
                    width: 15%;
                    flex: 0 1 15%;
                    align-self: flex-start;
                    padding-left: 0;
                    padding-bottom: 0;
                }
                &:nth-child(2) {
                    width: 85%;
                    flex: 0 1 85%;
                    align-self: flex-start;
                }
                &:nth-child(3) {
                    margin-left: 15%;
                }
            }
        }
    }



    /*
     *	CART
     */
}


@include media-breakpoint-down(xs) {

    /*
     *	GENERAL
     */

    .table th,
    .table td {
        padding: 6px 5px;
    }



    /*
     *	NAVBAR
     */

    .navbar--main {
        //margin-bottom: 30px;
    }


    /*
     *	BREADCRUMB
     */

    .breadcrumb {
        //margin-bottom: 30px;
    }



    /*
     *	CART
     */

    .cart-actions__amount {
        flex-wrap: wrap;
    }

    .cart-total {
        table {
            width: 100%;
        }
    }



    /*
     *	CHECKOUT PROGRESS
     */

    .checkout-steps {
        margin-bottom: 30px;
    }

}

@media (max-width: 400px) {

    /*
     *	FAVOURITES
     */

    .table--favourites {
        tbody {
            td {
                &:first-child {
                    display: none;
                }
                &:nth-child(2) {
                    width: 100%;
                    flex: 1 1 100%;
                }
                &:nth-child(3) {
                    margin-left: 0;
                }
            }
        }
    }



    /*
     *	CHECKOUT PROGRESS
     */

    .checkout-steps {
        flex-wrap: wrap;
    }
    .checkout-step {
        flex: 0 1 50%;
        max-width: 50%;
        margin: 0;
        border: 1px solid $body-bg;

        padding: 8px 5px;
    }
    .checkout-step__number {
        margin-bottom: 0;
        font-size: $font-size-base;
    }
    .checkout-step__title {
        font-size: $font-size-sm;
    }
}
