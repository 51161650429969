
/*
 *  Bootstrap variable overrides
 */

// Body
$primary: #00397e;
$secondary: #feda00;
$body-bg: #f8fafc;
$warning: #ffb900;
$success: #71d388;

// Custom
$primary-dark: #002652;
$primary-bright: #0071f3;
$primary-light: #879ab1;

$gray: #b4bbc4;

// Typography
$font-family-sans-serif: "Poppins", sans-serif;
$font-size-sm: .85rem;
$font-size-base: .95rem;
$line-height-base: 1.6;

// Colors
//$blue: #3490dc;
//$indigo: #6574cd;
//$purple: #9561e2;
//$pink: #f66D9b;
$red: #ff3600;
$orange: #fab41a;
$yellow: #feda00;
//$green: #38c172;
//$teal: #4dc0b5;
//$cyan: #6cb2eb;