/*
 *	BOOSTRAP STYLE OVERRIDES
 */

/* grid */

.wide-gutters {

}

/* text */

h1, .h1 {
	margin-bottom: 20px;
	font-size: 2.8rem;
}
h2, .h2 {
	margin-bottom: 30px;
	font-size: 1.6rem;
	font-weight: $font-weight-bold;
	color: $primary;
	letter-spacing: .05rem;
}
h3, .h3 {
	margin-bottom: 20px;
	font-size: 1.2rem;
	font-weight: bold;
	color: $primary-light;
}
.h4, h4 {
	margin-bottom: 20px;
	font-size: 1.2rem;
	font-weight: $font-weight-light;
	color: $primary-light;
}
.h5, h5 {

}
.h6, h6 {

}

p {
	margin-bottom: 30px;
}

a {
	transition: color .15s ease-out;
}

hr {
	margin: 30px 0;
	color: $primary-light;
}



/* buttons */

.btn {
	padding: 13px 25px;
	line-height: 1.2;
	border-radius: 30px;
	letter-spacing: .05rem;
	cursor: pointer;
}
.btn-sm {
	padding: 8px 15px;
	border-radius: 2px;
}
.btn-outline-secondary {
	border: 2px solid $secondary;
	color: $primary;
}
.btn-outline-dark {
	border: 2px solid #a9a9a9;
	color: $primary;
}
.btn-light {
	border: 1px solid #b7b7b7;
	background-color: #fff;
	color: $primary;

	&:hover,
	&:focus {
		border-color: $primary-bright;
		color: $primary-bright;
		background-color: #fff;
	}
}

.btn-clean {
	color: $primary;

	&:hover {
		color: $primary-bright;
		border-color: $primary-bright;
	}
	&:active {
		color: $primary-dark;
		border-color: $primary-dark;
	}
}

.btn-light {
	border-color: #ced4da;

	&:hover,
	&:focus {
		border-color: $primary;
		color: $primary;
	}
}


/* forms */

.form-control--changed {
	color: $primary-bright;
}

.form-check-input {
	background-color: #f7fafc;
}
label {
	margin-bottom: 5px;
	color: $primary;
	font-size: .85rem;
	letter-spacing: .05rem;
}
.form-check-input:checked + label {
	color: $primary-bright;
}

/* dropdown */

.dropdown-menu {
	min-width: 100%;
	background-color: #c6d2e0;
	border: none;

	&::before {
		content: '';
		display: block;
		position: absolute;
		top: 0;
		left: 50%;
		transform: translate(-50%, -100%);
		width: 0;
		border-left: 15px solid transparent;
		border-right: 15px solid transparent;
		border-bottom: 12px solid #c6d2e0;
	}
}
.dropdown-item {
	padding: 5px 15px;

	&:hover,
	&:active {
		color: #fff;
		font-weight: $font-weight-bolder;
		background-color: transparent;
	}
}

/* card */

.card {
	margin-bottom: 35px;
}


/* toast */

.toast-container {
	z-index: 999;
	position: fixed;
	right: 0;
	bottom: 0;
	padding: 15px;
	pointer-events: none;

	.toast {
		pointer-events: auto;
	}
}

.toast {
	background-color: #fff;
	border-color: $primary-dark;
}
.toast-header {
	color: $primary;
	font: $font-weight-normal;
	background-color: #e8edf2;
}

input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-decoration {
	opacity: 1;
}
